import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '@components/Layout'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { GatsbyImage } from 'gatsby-plugin-image'

import { BusinessBox, ContentBox, LogoBox, BusinessTel, TagBox, ContentStyle } from './styles'

const BusinessTemplate = ({ data }) => {
  const { title, addresslineone, addresslinetwo, town, postcode, telephone, websiteurl, twitter, facebook, instagram, logo, latitude, longitude } = data.datoCmsBusiness

  let mytags
  let tagsSection
  let addresslineoneSection
  let addresslinetwoSection
  let instagramSection
  let facebookSection
  let twitterSection
  let townSection
  let postcodeSection
  let telephoneSection
  let websiteurlSection
  let contentSection
  let locationSection
  let logoNewSection

  if (data.datoCmsBusiness.tags) {
    const tagsArray = data.datoCmsBusiness.tags

    mytags = tagsArray.map((tag, i) => {
      const lowerCaseTag = tag.category.toLowerCase()
      const slugTag = lowerCaseTag.replace(/\s/g, '')

      return (
        <TagBox className="sc-TagBox" key={tag.id}>
          <Link to={'/businesses/' + slugTag}>{tag.category}</Link>
        </TagBox>
      )
    })

    tagsSection = <span>{mytags}</span>
  }

  if (addresslineone) {
    addresslineoneSection = (
      <span>
        {addresslineone}
        <br />
      </span>
    )
  }

  if (addresslinetwo) {
    addresslinetwoSection = (
      <span>
        {addresslinetwo}
        <br />
      </span>
    )
  }

  if (town) {
    townSection = (
      <span>
        {town}
        <br />
      </span>
    )
  }

  if (postcode) {
    postcodeSection = (
      <span>
        {postcode}
        <br />
      </span>
    )
  }

  if (telephone) {
    telephoneSection = (
      <span>
        <BusinessTel href={`tel:${telephone}`}>{telephone}</BusinessTel>
        <br />
      </span>
    )
  }

  if (websiteurl) {
    websiteurlSection = (
      <span>
        <b>Website:</b>{' '}
        <a href={websiteurl} target="_blank" rel="noopener noreferrer">
          {websiteurl.replace(/^\/\/|^.*?:(\/\/)?/, '')}
        </a>
        <br />
      </span>
    )
  }

  if (facebook) {
    facebookSection = (
      <span>
        <b>Facebook:</b>{' '}
        <a href={`https://www.facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer">
          /{facebook}
        </a>
        <br />
      </span>
    )
  }

  if (twitter) {
    twitterSection = (
      <span>
        <b>Twitter:</b>{' '}
        <a href={`https://twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer">
          @{twitter}
        </a>
        <br />
      </span>
    )
  }

  if (instagram) {
    instagramSection = (
      <span>
        <b>Instagram: </b>
        <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
          /{instagram}
        </a>
        <br />
      </span>
    )
  }

  if (data.datoCmsBusiness.content !== 'unavailable') {
    contentSection = <ContentStyle className="sc-ContentStyle" dangerouslySetInnerHTML={{ __html: data.datoCmsBusiness.contentNode.childMarkdownRemark.html }} />
  }

  if (logo) {
    logoNewSection = (
      <span>
        <GatsbyImage image={logo.gatsbyImageData} alt={title} />
      </span>
    )
  }

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={18} defaultCenter={props.center}>
        <Marker position={props.center} />
      </GoogleMap>
    ))
  )

  if (latitude) {
    locationSection = (
      <span>
        <div className="businessmap">
          <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDiV-5xGeR_G6XZb3-qoAKayN1h8O0-wbQ&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={{ lat: latitude, lng: longitude }}
          />
        </div>
      </span>
    )
  }

  const seotitle = title + ' | In Sowerby Bridge The Town and Business Guide For Residents and Visitors'

  return (
    <Layout>
      <article className="post">
        <Seo title={seotitle} />
        <PageTitle title={title} />
        <BusinessBox>
          <ContentBox>
            {addresslineoneSection}
            {addresslinetwoSection}
            {townSection}
            {postcodeSection}
            {telephoneSection}
            {websiteurlSection}
            {facebookSection}
            {twitterSection}
            {instagramSection}
            {contentSection}
          </ContentBox>
          <LogoBox>{logoNewSection}</LogoBox>
        </BusinessBox>
        {tagsSection}
        {locationSection}
      </article>
    </Layout>
  )
}

export const businessListingQueryGraph = graphql`
  query businessListingQuery($slug: String!) {
    datoCmsBusiness(slug: { eq: $slug }) {
      id
      instagram
      content
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      addresslineone
      addresslinetwo
      title
      telephone
      town
      twitter
      facebook
      websiteurl
      longitude
      latitude
      postcode
      mobile
      logo {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: TRACED_SVG)
      }
      tags {
        id
        category
      }
    }
  }
`

export default BusinessTemplate
