import styled from 'styled-components'
import { media } from '../../theme/utils/media'
import { colours } from '../../theme/variables'

export const BusinessBox = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
        flex-direction: row;
      `};
`

export const ContentBox = styled.div`
  flex: 2;
  order: 2;
  ${media.tablet`
        order: 1;
      `};
`

export const LogoBox = styled.div`
  flex: 1;
  text-align: center;
  order: 1;
  img {
    margin-bottom: 1em;
  }
  ${media.tablet`
        text-align: right;
        order: 2;
      `};
`

export const BusinessTel = styled.a`
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

export const TagBox = styled.div`
  display: inline-block;
  background-color: ${colours.pgwarmgrey};
  color: ${colours.pgwhite};
  //margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  &:hover {
    background-color: ${colours.pggreen};
  }

  a {
    display: block;
    color: ${colours.pgwhite};
    padding: 0.5em;
    &:hover {
      background-color: ${colours.pggreen};
      color: ${colours.pgwhite};
    }
    &:visited {
      color: ${colours.pgwhite};
    }
  }
`
export const ContentStyle = styled.div`
  padding-top: 20px;
`
